import './App.css';

import Clock from "./components/Clock";

function App() {

  return (

    <>

        <Clock></Clock>


    </>

);
}


export default App
